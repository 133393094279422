import React from "react"
import styled from "styled-components"
import SEO from "../components/metaSEO"
import { Headline } from "../components/Styled"
import { FadeWrapper } from "../components/Transitions"
import { useColour } from "../utils/colourContext"
import { settings } from "../utils/settings"

export default () => {
  const { setColour } = useColour()
  setColour("0,0,0")
  return (
    <FadeWrapper>
      <SEO title="VR exhibition" />
      <Headline>VR exhibition</Headline>
      <Text>
        A selection of contemporary Norwegian painting spanning the last 40
        years.
      </Text>
      <Embed />
    </FadeWrapper>
  )
}

const Text = styled.div`
  font-size: ${settings.fontSize.medium};
  line-height: 1.4;
  max-width: 550px;
  position: relative;
  white-space: pre-line;
  left: 50%;
  transform: translateX(-50%);
  padding-bottom: 20px;
  text-align: center;
`

const Embed = () => (
  <iframe
    src="https://embed.artland.com/shows/christian-bjelland-collection"
    width="100%"
    height="640px"
    frameBorder="0"
    scolling="no"
    title="Bjelland Collection Exhibition"
    allowFullScreen={true}
  ></iframe>
)
